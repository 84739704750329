<template>
  <main v-if="steps == 2" @click.stop="createMap()" class="create-map-mask-box">
    <div class="novice-tutor">
      <img src="../../../assets/img/noviceGuidance/tutor.svg" alt="新手引导" />
    </div>
    <div class="direction-arrow">
      <img src="../../../assets/img/noviceGuidance/down.svg" alt="新手引导" />
    </div>
    <div class="create-map-box-children" @click.stop="createMap()">
      <div style="width: 200px; height: 125px; overflow: hidden">
        <img
          src="../../../assets/img/mapIcon/res/mipmap-xxhdpi/left_right_layout_1.png"
          width="100%"
          alt="双向导图"
        />
      </div>
    </div>
    
    <div class="end-tutorial" @click.stop="endTutorial">
      {{ $getStringObj.getString($Strings.I_Get_It) }}
    </div>
    <div class="novice-content">
      <!-- 点击创建一张思维导图 -->
      <div>
        {{$getStringObj.getString($Strings.Click_Create_A_Mind_Map)}}
      </div>
      <span class="triangle"></span>
    </div>
  </main>
</template>
<script>
import { setCookie, getCookie } from "../../../common/netWork/base";
export default {
  data() {
    return {
      steps: 0,
    };
  },
  created() {
    let val = localStorage.getItem("newGuide");
    // let val = getCookie("newGuide");
    if (val) {
      this.setNoviceGuidance(0);
    } else {
      this.steps = 2;
      this.setNoviceGuidance(this.steps);
    }
  },
  methods: {
    setNoviceGuidance(val) {
      localStorage.setItem("noviceGuidance", val);
      // setCookie('noviceGuidance', val)
    },
    getSteps() {
      let val = localStorage.getItem("noviceGuidance");
      // let val = getCookie("noviceGuidance");
      if (val) {
        this.steps = val;
      }
    },
    endTutorial() {
      localStorage.setItem("newGuide","1")
      // setCookie('newGuide', 1);
      this.setNoviceGuidance(0);
      this.getSteps();
    },
    nextSteps() {
      this.steps++;
      this.setNoviceGuidance(this.steps);
    },
    createMap() {
      this.$store.state.createABidirectionalMap = true;
      this.nextSteps();
    },
  },
};
</script>
<style lang="less" scoped>
.create-map-mask-box {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  .novice-tutor {
    position: absolute;
    left: 60px;
    bottom: 60px;
  }
  .direction-arrow {
    position: absolute;
    left: 530px;
    top: 86px;
  }
  .create-map-box-children {
    position: absolute;
    left: 430px;
    top: 160px;
    width: 200px;
    height: 125px;
    margin: 10px 0px 10px 30px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  .novice-content {
    padding: 10px;
    background: #ffffff;
    border-radius: 2px;
    color: #333333;
    font-size: 14px;
    position: absolute;
    left: 320px;
    bottom: 190px;
  }
  .triangle {
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-right-color: #ffffff;
    border-left: 0;
    position: absolute;
    left: -10px;
    bottom: 5px;
  }
  .end-tutorial {
    // width: 56px;
    // height: 28px;
    padding: 4px 7px;
    border: 1px dashed #ffffff;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    right: 40px;
    bottom: 40px;
    cursor: pointer;
  }
}
.create-map {
  color: #fc5d40;
  cursor: pointer;
}
</style>

<template>
  <div class="box-outer">
    <!-- <div class="left-button"> -->
      <!-- <div class="left-button-crumbs">
        <span class="mind-load" v-for="(item, index) in crumbs" :key="index">
          {{ item.name }}
        </span>
      </div> -->
    <!-- </div> -->
    <div class="top-fast-create-mode">
      <div class="top-fast-create-mode-cell"
            @click="onCreateEmptyMindMap"
        >
        <div class="top-fast-create-mode-cell-background">
          <img class="top-fast-create-mode-cell-icon"
            src="../../assets/images/create_mindmap_icon.png"
            alt=""
          />
        </div>
        <span class="top-fast-create-mode-cell-label">
            {{ getString(Strings.My_Mind_Blank_Mind_Map) }}
          </span>
      </div>

      <div class="top-fast-create-mode-cell"
            @click="onCreateEmptyFlowChartMindMap"
        >
        <div class="top-fast-create-mode-cell-background"
          style="background: linear-gradient(to right, #39C078, #5FC08C);">
          <img class="top-fast-create-mode-cell-icon"
            src="../../assets/images/create_flow_chart_icon.png"
            alt=""
          />
        </div>
        <span class="top-fast-create-mode-cell-label">
            {{ getString(Strings.Mind_Flow_Chart_Map) }}
        </span>
      </div>

      <div class="top-fast-create-mode-cell"
        @click="onCreateFolder">
        <div class="top-fast-create-mode-cell-background"
         style="background: linear-gradient(to right, #F78C87, #EC3C12);">
          <img class="top-fast-create-mode-cell-icon"
            src="../../assets/images/create_file_icon.png"
            alt=""
          />
        </div>
        <span class="top-fast-create-mode-cell-label">
            {{ getString(Strings.My_Mind_Create_Folder) }}
          </span>
      </div>

      <div class="top-fast-create-mode-cell"
        @click="turnOnScanCreation">
        <div class="top-fast-create-mode-cell-background"
          style="background: linear-gradient(to right, #F9C47A, #F68022);">
          <img class="top-fast-create-mode-cell-icon"
            src="../../assets/images/mind_create_scan_2.png"
            alt=""
          />
        </div>
        <span class="top-fast-create-mode-cell-label">
            {{ getString(Strings.Mind_Scan_Creation) }}
          </span>
      </div>

      <div class="top-fast-create-mode-cell"
        @click="onShowFileImportWindow">
        <div class="top-fast-create-mode-cell-background"
          style="background: linear-gradient(to right, #c2a8eb, #b54fff);">
          <img class="top-fast-create-mode-cell-icon"
            src="../../assets/images/mind_create_import_icon.png"
            alt=""
          />
        </div>
        <span class="top-fast-create-mode-cell-label">
            {{ getString(Strings.Mind_Import_File) }}
          </span>
      </div>
    </div>

    <div v-for="(item, index) in templateData" :key="index" class="modal-class">
      <template v-if="index < 6">
        <div class="modal-class-name">
          {{ getString(item[0].name )}}
        </div>
        <section v-if="index == -1" class="scan-creation-box">
          <span class="scan-creation-title" @click="turnOnScanCreation" >
            {{ getString(Strings.Mind_Scan_Creation) }}
          </span>
          <img @click="turnOnScanCreation"
            src="../../assets/images/ocr/scan_extraction.svg"
            alt=""
          />
        </section>
        
        <div v-show="index == 0" 
          class="create-node-storage-mode">
          <span class="operation-label">
            {{ getString(Strings.Create_Mind_Storage_Mode) }}
          </span>
          <a-switch
            v-model="createMindStorageMode"
            size="small"
            @change="onChangeStorageMode"
          />
        </div>
        <NewCreatedFileScroll
          :data="item"
          :storageCloud="createMindStorageMode"
          @clickCreated="clickCreated"
          :folder="crumbs.slice(-1)[0]"
        />
      </template>
      <template v-else>
        <div class="modal-class-name">
          {{ getString(item[0].name )}}
        </div>
        <CreatedFileScroll
          :data="item"
          :storageCloud="createMindStorageMode"
          @clickCreated="clickCreated"
          :folder="crumbs.slice(-1)[0]"
        />
      </template>

    </div>
      <!-- 创建页面新手引导 -->
    <!-- <CreateMapNewGuide/> -->
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { postTemplateNewFromTemplate } from "../../common/netWork/template_api";
import CreateMapDatas from "../../common/CreateMapDatas";
import CreatMindItmeData from "../../views/uidata/CreatMindItmeData";
import LineLayout from "../../viewmodel/datatype/LineLayout";
import MindDisplayType from "../../viewmodel/datatype/MindDisplayType";
import MindType from "../../viewmodel/datatype/MindType";
import NodeLayoutType from "../../viewmodel/datatype/NodeLayoutType";
import ConfigurationItem from "../../assets/js/ConfigurationItem.js";
const { numberOfMapsTourist } = ConfigurationItem;
import {
  eventBusMethod,
} from "../../assets/js/GlobalVariable.js";
import CreatedFileScroll from "./createdFileScroll/CreatedFileScroll";
import NewCreatedFileScroll from "./createdFileScroll/NewCreatedFileScroll";
import Strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import {
  getJwt,
} from "../../common/netWork/base";
import createdMindData from "../../common/createdMindData";
import CreateMapNewGuide from "../complieComponents/noviceGuidance/CreateMapNewGuide.vue";
import { postMindmapListDir, postMindmapNewByType } from '../../common/netWork/mind_map_api';
import NewLocalMindParameter from '../../core/core/dataformat/fileImport/NewLocalMindParameter';
import NewXJMindMap from '../../core/core/dataformat/fileImport/NewXJMindMap';
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}

export default {
  components: {
    CreatedFileScroll,
    NewCreatedFileScroll,
    CreateMapNewGuide
  },
  data() {
    return {
      Strings,
      templateData: [],
      creatMindTypeLeftRight: [],
      creatMindTypeUnilateral: [],
      creatMindTypeBottom: [],
      creatMindTypeBubble: [],
      creatMindTypeTimeVertical: [],
      urlPrefix: "../../../static/img/mapIcon/res/mipmap-xxhdpi/", //地址前缀
      crumbs: [{ id: "", name: getString(Strings.Create_Map) }], //面包屑
      createMindStorageMode:true,
    };
  },
  created() {
    this.templateData = createdMindData
    // this.createdType();
    //创建页面时要请求vuex里面的面包屑
    this.crumbs = this.$store.state.createdMindCrumbsArr;
  },
  mounted() {},
  methods: {
    ...mapMutations([
      "showLoginModel",
      "refreshMyMindList",
      "addFolder",
      "showFileImportWindow",
    ]),
    getString(i) {
      return getString(i);
    },
    onCreateFolder() {
      this.addFolder()
    },
    onShowFileImportWindow() {
      this.showFileImportWindow()
    },
    onCreateEmptyMindMap(){
      let item = new CreatMindItmeData("mind_skeleton_0.png", Strings.My_Mind_Type_Left_Right, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.LINE_MAP, 1.645, 0)
      this.onCreateMindMap(item);
    },

    onCreateEmptyFlowChartMindMap() {
      let item = new CreatMindItmeData("left_right_layout_empty.jpg", Strings.My_Mind_Type_Left_Right,
                                        NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.FLOW_CHART, 1.645, 2,
                                        LineLayout.CURVE_LINE_2, MindDisplayType.MindMap, true)

      this.onCreateMindMap(item);
    },

    onCreateMindMap(item) {
      if (item == null) {
        return
      }
      let parentEntityId = this.crumbs.slice(-1)[0].id; //  父文件夹id,默认为空
      let type = item.mindType; //  导图类型
      let mindDisplayType = item.mindDisplayType; //  导图模式
      let nodeLayoutType = item.layoutType; //  导图布局
      let lineLayout = item.lineLayout; //  线条类型
      let isEmpty = true; //  是否是空导图
      let styleIndex = item.styleIndex; //  styleIndex
      let data = {
        parentEntityId,
        type,
        mindDisplayType,
        nodeLayoutType,
        lineLayout,
        isEmpty,
        styleIndex,
      };
      // folder
      if (!this.createMindStorageMode) { //只创建到本地
        var name = item.name;
        if ((typeof name) == "number") {
            try {
              name = getString(name)
            } catch (error) {
              name = name + "";
            }
        }
        let newLocalMindParameter = new NewLocalMindParameter()
        newLocalMindParameter.parentEntityId = parentEntityId
        newLocalMindParameter.type = type
        newLocalMindParameter.mindDisplayType = mindDisplayType
        newLocalMindParameter.nodeLayoutType = nodeLayoutType
        newLocalMindParameter.lineLayout = lineLayout
        newLocalMindParameter.isEmpty = isEmpty
        newLocalMindParameter.styleIndex = styleIndex
        let parameter = newLocalMindParameter.getJson()
        new NewXJMindMap(name, type, this.$router.appPath, parameter).createLocalMindMap().then((creearData) => {

        })
        return
      }
      // 未登录导图数量超过1个弹出登陆，否则不能创建
      postMindmapListDir(
        { parentId: "" },
        (res) => {
          localStorage.setItem("mapsNumber", res.entities.length);
        },
        (e) => {
          this.$message.error(e.desc);
        }
      );
      const mapsNumber = localStorage.getItem("mapsNumber") || 0;
      if (mapsNumber >= numberOfMapsTourist && !getJwt("jwt")) {
        this.$store.state.showLogin = true;
        return;
      }
      if (this.$tools.isElectron()) {
        postMindmapNewByType(
          data,
          (res) => {
            ipcRenderer.send(
              "openCalendarWindow",
              "/mindmap?id=" + res.mindMapId
            );
            this.refreshMyMindList();
          },
          (error) => {
            if (error.code == 51) {
              this.$message.error(getString(Strings.Insufficient_Storage_Space));
            } else {
              this.$message.error(error.desc);
            }
          }
        );
      } else {
        postMindmapNewByType(
          data,
          (res) => {
            const routerData = this.$router.resolve({
              path: "/mindmap",
              query: {
                id: res.mindMapId,
              },
            });
            this.createATagJump(routerData.href);
            this.refreshMyMindList();
          },
          (error) => {
            if (error.code == 51) {
              this.$message.error(getString(Strings.Insufficient_Storage_Space));
            } else {
              this.$message.error(error.desc);
            }
          }
        );
      }
    },

    turnOnScanCreation(){
      // 打开扫描创建
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
      } else {
        this.bus.$emit(eventBusMethod.ScanCreationEvent);
      }
    },
    pullModalData(obj) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postTemplateNewFromTemplate(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    clickCreated(item) {
      this.bus.$emit("changeCreated", item);
    },
    onChangeStorageMode() {
      if (!this.$tools.isElectron()) {
        setTimeout(() => {
            this.createMindStorageMode = true
        }, 200);
        this.$message.success(getString(Strings.Public_Header_Download_Apps));

        setTimeout(() => {
            new UiUtil().createATagOpenUrl("https://www.mindyushu.com/downloads.html");
        }, 1000);
      }
    },
    createdType() {
      let createMapDatas = new CreateMapDatas()
      this.creatMindTypeLeftRight = createMapDatas.creatMindTypeLeftRight
      this.creatMindTypeUnilateral = createMapDatas.creatMindTypeUnilateral
      this.creatMindTypeBottom = createMapDatas.creatMindTypeBottom
      this.creatMindTypeBubble = createMapDatas.creatMindTypeBubble
      this.creatMindTypeTimeVertical = createMapDatas.creatMindTypeTimeVertical
      
      this.templateData.push(this.creatMindTypeLeftRight);
      this.templateData.push(this.creatMindTypeUnilateral);
      this.templateData.push(this.creatMindTypeBottom);
      this.templateData.push(this.creatMindTypeBubble);
      this.templateData.push(this.creatMindTypeTimeVertical);
      for (let i = 0; i < this.templateData.length; i++) {
        for (let j = 0; j < this.templateData[i].length; j++) {
          if (this.templateData[i][j] != null) {
            this.templateData[i][
              j
            ].icon = require("../../assets/img/mindNewEditMenuIocn/oneKeyDiscoloration/skeleton2/" +
              this.templateData[i][j].icon + ".png");
          }
        }
      }
    },
  },
  watch: {
    "$store.state.createdMindCrumbsArr"(newval, olval) {
      this.crumbs = newval;
    },
  },
};
</script>

<style lang="less" scoped>
.box-outer {
  user-select: none;
  padding: 20px 0;
  margin-top: 40px;
}
.top-fast-create-mode {
  height: 130px;
  width: 100%;
  // background: #333333;
  .top-fast-create-mode-cell {
    height: 110px;
    width: 120px;
    text-align: center;
    margin-left: 30px;
    float: left;
    cursor: pointer;
    .top-fast-create-mode-cell-background {      
      height: 80px;
      width: 120px;
      border-radius: 8px;
      text-align: center;
      background: linear-gradient(to right, #48C3FB, #3887EE);
      .top-fast-create-mode-cell-icon {      
        height: 34px;
        width: 34px;
        margin-top: 20px;
      }
    }
    .top-fast-create-mode-cell-label {
      margin-top: 8px;
      text-align: center;
      font-size: 14px;
      color: #666;
      display: block;
      margin-top: 8px;
    }
  }
}
.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-class {
  margin-bottom: 25px;
  .modal-class-name {
    font-size: 14px;
    padding-left: 30px;
    // padding-top: 30px;
    padding-bottom: 5px;
    color: #333;
    font-weight: bold;
  }
  .scan-creation-box{
      position: absolute;
      top: 20px;
      left: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .scan-creation-title{
        font-size: 14px;
        color: #333333;
        cursor: pointer;
      }
      img{
        margin-left: 4px;
        cursor: pointer;
      }
    }
}

.create-node-storage-mode {
    position: absolute;
    top:20px;
    right: 20px;
    align-items:center;
    align-content:center;
    .operation-label {
      margin: 0 10px 0 0px;
      font-size: 14px;
      color: #333333;
    }
  }

.left-button {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  min-height: 50px;
  line-height: 25px;
  margin-bottom: 20px;
  margin-left: 20px;
  /* line-height: 50px; */
  flex-wrap: wrap;
  .left-button-crumbs {
    display: flex;
    align-items: center;
  }
  .refesh-mind-list {
    line-height: 25px;
    font-size: 20px;
    margin-left: 20px;
    margin-top: 3px;
    cursor: pointer;
    &:active {
      color: #fd492b;
    }
  }
  .mind-load {
    font-size: 18px;
    cursor: pointer;
  }

  .mind-load:nth-last-child(1) i {
    display: none;
  }
}
</style>